import { graphql } from 'gatsby';
import React from 'react';
import SingleTemplate from './single';
import { getIndexPageMeta } from '../utils/helpers';
import { PAGE_TYPE } from '../utils/constants';
import DefaultProxy from '../components/proxy/blocks';

const RegularTemplate = props => {
  const { site, markdownRemark } = props.data;

  const seo = getIndexPageMeta({
    pathname: props.location.pathname,
    siteMetadata: site.siteMetadata,
    frontmatter: markdownRemark.frontmatter,
  });

  return (
    <SingleTemplate
      seo={seo}
      pageType={PAGE_TYPE.Default}
      proxy={DefaultProxy}
      {...props}
    />
  );
};

export default RegularTemplate;

export const regularPageQuery = graphql`
  query RegularPageQuery(
    $slug: String!
    $parentPageSlug: String = null
    $desktopHeader: String = "/menu_header.json/"
    $mobileHeader: String = "/menu_header_mobile.json/"
  ) {
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...PageMarkdownFragment
    }
    parentPage: markdownRemark(fields: { slug: { eq: $parentPageSlug } }) {
      ...ParentPageMarkdownFragment
    }
    graphData: sidebarJson {
      ...ProvidersFragment
      ...ContinentsFragment
      ...ContinentEntriesFragment
      ...WorldwideEntriesFragment
    }
    ...ConfigFragment
    headerMenu: menuJson(
      parent: { internal: { description: { regex: $desktopHeader } } }
    ) {
      ...MenusFragment
    }
    mobileMenu: menuJson(
      parent: { internal: { description: { regex: $mobileHeader } } }
    ) {
      ...MenusFragment
    }
  }
`;

export const configFragment = graphql`
  fragment ConfigFragment on Query {
    config: configJson(_template: { eq: "site_config" }) {
      global_meta_tags {
        name
        content
      }
      variables {
        id
        value
      }
      inline_scripts {
        raw_html
        disabled
      }
      external_scripts {
        src
        load_strategy
        disabled
      }
      top_banner {
        enabled
        text
        cta_text
        cta_url
      }
      hiring_banner {
        enabled
        image
        title
        subtitle
        url
        image_alt_text
      }
      fluffee_blacklist
    }
  }
`;

export const ImageFieldFragment = graphql`
  fragment ImageFieldFragment on ImageField {
    _template
    alt
    mobile_image_placement {
      placement
      max_width
    }
    offset {
      horizontal
      vertical
    }
    responsive_width {
      unit
      desktop
      mobile
    }
    rive_autoplay
    rive_image
    rive_state_machine_name
    scale {
      x
      y
    }
    src
  }
`;

export const TextTemplateFragment = graphql`
  fragment TextTemplateFragment on TextTemplate {
    text
    color
    font_size
  }
`;

export const BorderTemplateFragment = graphql`
  fragment BorderTemplateFragment on BorderTemplate {
    size
    color
  }
`;

export const ManualPositioningFragment = graphql`
  fragment ManualPositioningFragment on ManualPositioning {
    desktop {
      scale {
        x
        y
      }
      offset {
        horizontal
        vertical
      }
    }
    mobile {
      scale {
        x
        y
      }
      offset {
        horizontal
        vertical
      }
    }
  }
`;

export const ViewportTextFragment = graphql`
  fragment ViewportTextFragment on ViewportText {
    desktop
    desktop_line_height
    desktop_text_size
    mobile
    mobile_line_height
    mobile_text_size
  }
`;

export const SettingsFragment = graphql`
  fragment SettingsFragment on Settings {
    css_class_name
    custom_css
    negative_margin
    padding_top
    padding_bottom
    margin_top
    margin_bottom
  }
`;

export const LayoutSettingsFragment = graphql`
  fragment LayoutSettingsFragment on LayoutSettings {
    _template
    background_color
    padding_top
    padding_bottom
    margin_top
    margin_bottom
  }
`;

export const richSnippetsFragment = graphql`
  fragment RichSnippetsFragment on RichSnippet {
    ... on ProductReviewSnippet {
      __typename
      _template
      name
      rating_value
      rating_count
      best_rating
      worst_rating
      same_as
    }
  }
`;

export const ParentPageMarkdownFragment = graphql`
  fragment ParentPageMarkdownFragment on MarkdownRemark {
    frontmatter {
      url_alias
      sections {
        blocks {
          _template
          submenu_field {
            menu_group_title
            menu_items {
              menu_title
              menu_url
              menu_class_name
              submenu_level_1 {
                submenu_title
                submenu_url
              }
            }
          }
        }
      }
    }
  }
`;

export const PageMarkdownFragment = graphql`
  fragment PageMarkdownFragment on MarkdownRemark {
    id
    excerpt
    html
    fileAbsolutePath
    fields {
      slug
    }
    frontmatter {
      menu_src_override
      header_color
      custom_header_desktop
      custom_header_mobile
      url_alias
      title
      hides {
        footer_top
        footer_bottom
        header_menu_items
        header_menu
      }
      meta {
        description
        keywords
        og_image
        rich_snippets {
          ...RichSnippetsFragment
        }
      }
      parent_page
      sections {
        title
        section_margin
        is_bottom_curved
        background_color
        background_image
        background_illustration
        has_shadow
        layout_settings {
          ...LayoutSettingsFragment
        }
        rounded_corners
        reverse_gradient
        settings {
          ...SettingsFragment
        }
        blocks {
          offset {
            horizontal
            vertical
          }
          scale {
            x
            y
          }
          _template
          settings {
            ...SettingsFragment
          }
          left {
            pre_title
            title
            subtitle
            text
            image_alt_text
            image
            icon
            time_label
            icon_alt_text
            load_time_override
            description_label
          }
          right {
            pre_title
            title
            subtitle
            text
            image_alt_text
            image
            icon
            logo
            time_label
            icon_alt_text
            load_time_override
            description_label
          }
          ...PricingFragment
          ...PricingCardsV2Fragment
          block_visibility
          legend_visibility
          thank_you_page
          campaign_type
          dates
          people
          submit_url
          title_id
          form {
            _template
            campaign
            settings {
              ...SettingsFragment
            }
            side_image
            side_image_alt
            submit_label
            support_text
          }
          hide_image_on_mobile
          center_text_on_mobile
          show_additional_items_before_cta
          title
          title_mobile
          text_type
          padding_top
          padding_bottom
          pre_title
          subtitle
          subtitle_mobile
          intro_text
          text
          html
          small_text
          image
          image_alt_text
          image_position
          image_max_width
          default_storage_amount
          default_storage_unit
          default_disk_type
          show_disk_type_toggle
          default_regions
          align
          show_bunnies_image
          add_form_margin
          region
          region_label
          text_width
          form_style
          side_cta {
            title
            url
          }
          submenu_field {
            menu_group_title
            menu_items {
              menu_title
              menu_url
              menu_class_name
              submenu_level_1 {
                submenu_title
                submenu_url
              }
            }
          }
          mobile {
            text
          }
          cta_field {
            ...CtaFieldFragment
          }
          manual_positioning {
            ...ManualPositioningFragment
          }
          cta_contact {
            cta_text
            cta_url
            cta_position
            cta_subtext
            subtext_right
            style
            second_cta {
              text
              url
              color
            }
          }
          cta_text
          cta_url
          cta_position
          cta_subtext
          position
          background_color
          squares {
            title
            text
            icon
          }
          group {
            label
            squares {
              title
              text
              icon
            }
          }
          features {
            title
            text
            icon
            style
          }
          theme
          tab_position
          header_col
          data_col
          product_list {
            title
            text
            url
            icon
            tag {
              text
              color
            }
          }
          columns {
            value
            text
            icon
            logo
          }
          reviews {
            ...ReviewFragment
          }
          text_position
          testimonials {
            text
            image_logo
            image_person
            quote
            name
            company
            image
            image_alt_text
          }
          items {
            title
            subtitle
            icon
            text
            name
            position
            image
            image_alt_text
            question
            answer
            timeline_title
            date
            price
            performance
            url
            cta_field {
              cta_text
              cta_url
              cta_position
              cta_subtext
              style
            }
            tab_title
            tab_title_mobile
            blocks {
              _template
              settings {
                ...SettingsFragment
              }
              title
              title_mobile
              text
              subtitle
              footer_text
              side_text
              pricing {
                title
                price
              }
              cta_text
              cta_url
              cta_position
              cta_subtext
              subtext_right
              style
              second_cta {
                text
                url
                color
              }
              mobile {
                text
              }
              image_position
              image_alt_text
              image
              cta_field {
                cta_text
                cta_url
                cta_position
                cta_subtext
                style
              }
              small_text
              align
              style
            }
          }
          rows_no
          show_numbers
          title_id
          submit_label
          support_text
          accordion {
            title
            title_id
            title_icon
            text
            cta_field {
              cta_text
              cta_url
              cta_position
              cta_subtext
              style
            }
          }
          style
          list_size
          html
          head_columns {
            highlighted
            title
            image
            text
          }
          rows {
            text
            title
            subtitle
            columns {
              text
              icon
              title
              subtitle
              bold
              rating {
                current_rating
                max_rating
                number_of_stars
              }
            }
          }
          right_top {
            title
            text
            icon
          }
          right_bottom {
            title
            text
            icon
          }
          footer_text
          side_text
          pricing {
            title
            price
          }
          container_width
          container_height
          additional_items {
            ...AdditionalItemsFragment
          }
          companies_list {
            ...CompanyFragment
          }
          case_study_grids {
            type
            case_studies {
              ...CaseStudyTileFragment
            }
          }
          rating {
            current_rating
            max_rating
            number_of_stars
          }
          ...MixedContentFragment
          ...EdgeComputeComparisonFragment
          ...CalculatorSettingsFragment
          ...StreamPriceComparisonFragment
          ...DynamicFormReusableFragment
          ...DynamicFormFragment
          ...TextWithImageBlockFragment
          ...CaseStudyPanelFragment
          ...LabeledRatingFragment
          ...MultiformFragment
          ...SimpleBannerFragment
          ...NewsletterFragment
          ...RiveFragment
          ...DiplomaFragment
          ...PlatformCardsFragment
          ...CareerFragment
          ...BlogHeroFragment
          ...CenteredHeroFragment
          ...ListFragment
          ...DisqusFragment
          ...GraphFragment
          ...AcademyMenuFragment
          ...HelpfulFragment
          ...ColumnsFragment
          ...CarouselLogoFragment
          ...CheckmarksFragment
          ...VideoBlockFragment
          ...VideoStreamBlockFragment
          ...IntroductionFragment
          ...TranscodingCalculatorFragment
          ...StrengthsFragment
          ...VideoEmbedBlockFragment
          ...TabsBlockFragment
          ...BTabsBlockFragment
          ...LargeCardItemsFragment
          ...UseCasesFragment
          ...TextWithLogosGridFragment
          ...SpotlightTabsBlockFragment
          ...EngagementPanelFragment
          ...FeaturesGridFragment
          ...ViewportImageFragment
          ...TestimonialsV2Fragment
          blocks {
            ...NestedRegularBlockFragment
            ...NestedColumnsBlockFragment
          }
          container_color
          font_color
          column_select
          show_index
          block_state
          type
          show_buttons
          show_slider
          hide_filter
          slider_theme
          dividing_space {
            unit
            desktop
            mobile
          }
        }
      }
    }
  }
`;

export const nestedRegularBlockFragment = graphql`
  fragment NestedRegularBlockFragment on NestedBlock {
    ... on RegularNestedFields {
      settings {
        ...SettingsFragment
      }
      scale_on_hover
      scale_by_default {
        mobile
        desktop
      }
      reviews {
        ...ReviewFragment
      }
      hide_on_mobile
      platforms
      justify_content
      align_items
    }
  }
`;

export const allMenusFragment = graphql`
  fragment MenusFragment on MenuJson {
    parent {
      ... on File {
        name
      }
    }
    menu_group_title
    menu_subtitle
    menu_items {
      menu_title
      menu_url
      footer {
        text
        cta_text
        cta_url
      }
      menu_icon
      menu_class_name
      submenu_level_1 {
        submenu_title
        submenu_url
        submenu_description
        submenu_icon
        submenu_class_name
        cta_style
        submenu_level_2 {
          title
          url
          description
          class_name
          icon
          cta_style
          submenu_level_3 {
            title
            url
            description
            class_name
            icon
            cta_style
          }
        }
      }
    }
  }
`;

export const MobileImagePlacementFragment = graphql`
  fragment MobileImagePlacementFragment on MobileImagePlacement {
    placement
    max_width
  }
`;

export const ResponsiveWidthFragment = graphql`
  fragment ResponsiveWidthFragment on ResponsiveWidth {
    unit
    desktop
    mobile
  }
`;
export const OffsetFragment = graphql`
  fragment OffsetFragment on Offset {
    horizontal
    vertical
  }
`;

export const ScaleFragment = graphql`
  fragment ScaleFragment on Scale {
    x
    y
  }
`;

export const reusableImageFieldFragment = graphql`
  fragment ReusableImageFieldFragment on ReusableImageField {
    src
    alt
    hide_image_on_mobile
    mobile_image_placement {
      ...MobileImagePlacementFragment
    }
    responsive_width {
      ...ResponsiveWidthFragment
    }
    offset {
      ...OffsetFragment
    }
    scale {
      ...ScaleFragment
    }
  }
`;

export const reusableTextField = graphql`
  fragment ReusableTextFieldFragment on ReusableTextField {
    input_text
    input_font_size
    input_line_height
    input_font_color
    margin_top
    margin_bottom
  }
`;

export const reusableReverseContentOrderField = graphql`
  fragment ReusableReverseContentOrder on ReusableReverseContentOrder {
    reverse_desktop
    reverse_mobile
  }
`;

export const reusableContainerPadding = graphql`
  fragment ReusableContainerPadding on ReusableContainerPaddingOptions {
    left
    right
  }
`;

export const testimonialsV2Fragment = graphql`
  fragment TestimonialsV2Fragment on Block {
    section_background
    content_background
    border_radius
    reverse_option {
      ...ReusableReverseContentOrder
    }
    container_padding {
      ...ReusableContainerPadding
    }
    container_gap
    content_alignment
    name_text {
      ...ReusableTextFieldFragment
    }
    title_text {
      ...ReusableTextFieldFragment
    }
    quote_text {
      ...ReusableTextFieldFragment
    }
    reusable_image_field {
      ...ReusableImageFieldFragment
    }
  }
`;
